// Generated by Framer (532f6a7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/nFAy8p4fOASsyhPbo192/Ticker.js";
import TickerImages from "./jLQ6WJGJ7";
const TickerImagesFonts = getFonts(TickerImages);
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["SYLNfkSvj", "xtYOa1IIG"];

const variantClassNames = {SYLNfkSvj: "framer-v-g7nlyp", xtYOa1IIG: "framer-v-1ysb8cc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "SYLNfkSvj", "Variant 2": "xtYOa1IIG"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "SYLNfkSvj", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SYLNfkSvj", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-koUNi", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-g7nlyp", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SYLNfkSvj"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({xtYOa1IIG: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-16ovkp2-container"} layoutDependency={layoutDependency} layoutId={"JxhE10FRK-container"} transition={transition}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={10} height={"100%"} hoverFactor={0.4} id={"JxhE10FRK"} layoutId={"JxhE10FRK"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: false, widthType: true}} slots={[<motion.div className={"framer-hxc7d8-container"} layoutDependency={layoutDependency} layoutId={"iUm5Wx73f-container"} transition={transition}><TickerImages height={"100%"} id={"iUm5Wx73f"} layoutId={"iUm5Wx73f"} variant={"XJ3dW2uXo"} width={"100%"}/></motion.div>, <motion.div className={"framer-hxc7d8-container"} layoutDependency={layoutDependency} layoutId={"iUm5Wx73f-container"} transition={transition}><TickerImages height={"100%"} id={"iUm5Wx73f"} layoutId={"iUm5Wx73f"} variant={"XJ3dW2uXo"} width={"100%"}/></motion.div>]} speed={140} style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({xtYOa1IIG: {sizingOptions: {heightType: false, widthType: false}}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-koUNi [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-koUNi .framer-16jceua { display: block; }", ".framer-koUNi .framer-g7nlyp { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-koUNi .framer-16ovkp2-container { flex: none; height: 820px; position: relative; width: 100%; }", ".framer-koUNi .framer-hxc7d8-container { height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-koUNi .framer-g7nlyp { gap: 0px; } .framer-koUNi .framer-g7nlyp > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-koUNi .framer-g7nlyp > :first-child { margin-top: 0px; } .framer-koUNi .framer-g7nlyp > :last-child { margin-bottom: 0px; } }", ".framer-koUNi.framer-v-1ysb8cc .framer-16ovkp2-container { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 820
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"xtYOa1IIG":{"layout":["fixed","auto"]}}}
 */
const FramerSmvhxk9Lx: React.ComponentType<Props> = withCSS(Component, css, "framer-koUNi") as typeof Component;
export default FramerSmvhxk9Lx;

FramerSmvhxk9Lx.displayName = "About Ticker";

FramerSmvhxk9Lx.defaultProps = {height: 820, width: 1200};

addPropertyControls(FramerSmvhxk9Lx, {variant: {options: ["SYLNfkSvj", "xtYOa1IIG"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerSmvhxk9Lx, [...TickerImagesFonts, ...TickerFonts])